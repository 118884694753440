* {
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-style: normal;
  font-weight: normal;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-internal-autofill-selected {
  -webkit-text-fill-color: #292524;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  box-shadow: 0 0 0px 1000px #fff inset;
}


@keyframes scaleUp {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
  


.toast-barr * {
  font-weight:  500 !important;
  font-size: 15px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
}

#portal-root {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 2147483647;
}

#float-toast-portal {
  z-index: 2147483647;
  position: fixed;
}

#mono-connect--widget-div {
  pointer-events: all;
}

/******* FONTS *******/

/* Menlo */
@font-face {
  font-family: "Menlo";
  src: url("./assets/fonts/Menlo-Regular.ttf");
}

/* sf pro */

@font-face {
  font-family: "float pro";
  src: url("./assets/fonts/SF-Pro.ttf");
}

@font-face {
  font-family: "swipe regular";
  src: url("./assets/fonts/SF-Pro-Display-Regular.otf");
}

@font-face {
  font-family: "swipe medium";
  src: url("./assets/fonts/SF-Pro-Display-Medium.otf");
}

@font-face {
  font-family: "swipe semi";
  src: url("./assets/fonts/SF-Pro-Display-Semibold.otf");
}

@font-face {
  font-family: "swipe bold";
  font-weight: bold;
  src: url("./assets/fonts/SF-Pro-Display-Bold.otf");
}
@font-face {
  font-family: "swipe heavy";
  src: url("./assets/fonts/SF-Pro-Display-Heavy.otf");
}

@font-face {
  font-family: "swipe rounded medium";
  src: url("./assets/fonts/SF-Pro-Rounded-Medium.otf");
}

@font-face {
  font-family: "swipe rounded semibold";
  src: url("./assets/fonts/SF-Pro-Rounded-Semibold.otf");
}

/* inter */
/* @font-face {
  font-family: 'swipe regular';
  src: url('./assets/fonts/inter/Inter-Regular.ttf');
}

@font-face {
  font-family: 'swipe medium';
  src: url('./assets/fonts/inter/Inter-Medium.ttf');
}

@font-face {
  font-family: 'swipe semi';
  src: url('./assets/fonts/inter/Inter-SemiBold.ttf');
}

@font-face {
  font-family: 'swipe bold';
  src: url('./assets/fonts/inter/Inter-Bold.ttf');
}
@font-face {
  font-family: 'swipe heavy';
  src: url('./assets/fonts/inter/Inter-Black.ttf');
} */


/* haffer */

 @font-face {
  font-family: 'haffer regular';
  src: url('./assets/fonts/haffer/haffer-regular.woff2');
}

@font-face {
  font-family: 'haffer medium';
  src: url('./assets/fonts/haffer/haffer-medium.woff2');
}

@font-face {
  font-family: 'haffer semi';
  src: url('./assets/fonts/haffer/haffer-semibold.woff2');
}

@font-face {
  font-family: 'haffer bold';
  src: url('./assets/fonts/haffer/haffer-bold.woff2');
}
@font-face {
  font-family: 'haffer heavy';
  src: url('./assets/fonts/haffer/haffer-heavy.woff2');
} 